import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";
import moment from 'moment'

const ItemHover = props => {

    const { text, item } = props;

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <span>

            <Tooltip
                fade={false}
                placement='top'
                isOpen={tooltipOpen}
                target={"Tooltip-" + item.id}
                toggle={toggle}
            >
                <div>
                    {item.title}
                </div>
                <div>
                    Start: {moment(item.start).format('YYYY-MM-DD HH:mm')}
                </div>
                <div>
                    End:   {moment(item.end).format('YYYY-MM-DD HH:mm')}
                </div>
            </Tooltip>
        </span>
    );
};


export default ItemHover;