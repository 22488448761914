import React, { Fragment, Component } from 'react';
import Breadcrumb from "../common/breadcrumb";
import moment from 'moment'
import 'react-big-calendar/lib/sass/styles.scss';
import { ActiveTimelineTitle } from "../../constant";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import 'react-calendar-timeline/lib/Timeline.css'
import { Form, Input, DatePicker, Button, message, Select } from 'antd';
import 'antd/dist/antd.css';
import EventList from './ViewEventPage'
import ColorPicker from './colorpicker'
import axios from 'axios'
import ItemHover from './itemHover.js'
import Timeline, {
    TimelineMarkers,
    TimelineHeaders,
    TodayMarker,
    CustomMarker,
    CursorMarker,
    CustomHeader,
    SidebarHeader,
    DateHeader
} from 'react-calendar-timeline'

import './calender1.css'
import { groups } from './groups'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'


const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'title',
    groupRightTitleKey: 'rightTitle',
    itemIdKey: 'id',
    itemTitleKey: 'title',
    itemDivTitleKey: 'title',
    itemGroupKey: 'group',
    itemTimeStartKey: 'start',
    itemTimeEndKey: 'end'
}
const minTime = moment().add(-6, "months").valueOf();
const maxTime = moment().add(6, "months").valueOf();
const { Option } = Select;

class Calender1 extends Component {

    formRef = React.createRef();

    constructor(props) {
        super(props);
        const defaultTimeStart = moment()
            .startOf('day')
            .toDate()
        const defaultTimeEnd = moment()
            .startOf('day')
            .add(1, 'day')
            .toDate()
        this.state = {
            groups: groups,
            items: [],
            defaultTimeStart,
            defaultTimeEnd,
            addModal: false,
            popoverOpen: false,
            item: [],
            clicked: false,
            hovered: false,
            timeValue: 0,
            endTimeValue: null,
            showEventList: false,
            showEventButtonDisplay: 'View Events',
            editOpen: false,
            editId: '',
            editindex: null,
            color: '',
            showHover: false
        }
        this.addToggle = this.addToggle.bind(this);
        this.handleEventListDelete = this.handleEventListDelete.bind(this);
        this.handleEventListEdit = this.handleEventListEdit.bind(this);
        this.editToggle = this.editToggle.bind(this);

    }



    handleItemMove = (itemId, dragTime, newGroupOrder) => {
        const { items, groups } = this.state;
        const group = groups[newGroupOrder];
        const item = items.find(item => item.id === itemId);
        console.log(itemId, dragTime, newGroupOrder);
        let data = JSON.stringify({
            id: item.id,
            startTime: moment(dragTime).format('YYYY-MM-DDTHH:mm:ss'),
            endTime: moment((dragTime + (item.end - item.start))).format('YYYY-MM-DDTHH:mm:ss'),
            lane: parseInt(group.id),
            title: item.title,
            description: item.description,
            colour: item.color
        });
        const index = itemId;
        console.log(data);
        axios.put('https://activetimelineapi.azurewebsites.net/api/events/' + index, data, { headers: { "Content-Type": "application/json" } }).then((res) => {
            console.log(res);
            this.axiosGetEvents();
        }).catch((error) => {
            console.log(error);
        });
    }


    handleTimeChange = (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) => {
        if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
            updateScrollCanvas(minTime, maxTime);
        } else if (visibleTimeStart < minTime) {
            updateScrollCanvas(
                minTime,
                minTime + (visibleTimeEnd - visibleTimeStart)
            );
        } else if (visibleTimeEnd > maxTime) {
            updateScrollCanvas(
                maxTime - (visibleTimeEnd - visibleTimeStart),
                maxTime
            );
        } else {
            updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
        }
    };


    moveResizeValidator = (action, item, time) => {
        if (time < new Date().getTime()) {
            var newTime =
                Math.ceil(new Date().getTime() / (15 * 60 * 1000)) * (15 * 60 * 1000);
            return newTime;
        }

        return time;
    };



    handleItemResize = (itemId, time, edge) => {
        const { items } = this.state;
        const item = items.find(item => item.id === itemId);
        console.log(itemId, time);
        let data = JSON.stringify({
            id: item.id,
            startTime: moment(item.start).format('YYYY-MM-DDTHH:mm:ss'),
            endTime: moment(time).format('YYYY-MM-DDTHH:mm:ss'),
            title: item.title,
            description: item.description,
            colour: this.state.color,
            lane: parseInt(item.group)
        });
        const index = itemId;
        console.log(data);
        axios.put('https://activetimelineapi.azurewebsites.net/api/events/' + index, data, { headers: { "Content-Type": "application/json" } }).then((res) => {
            console.log(res);
            this.axiosGetEvents();
        }).catch((error) => {
            console.log(error);
        });
    }



    axiosGetEvents = () => {
        axios.get('https://activetimelineapi.azurewebsites.net/api/events').then((res) => {

            let itemsData = [];

            res.data.map((user) => {

                itemsData.push({
                    id: user.id,
                    color: user.colour,
                    group: user.lane,
                    title: user.title,
                    description: user.description,
                    canMove: true,
                    start: moment(user.startTime).valueOf(),
                    end: moment(user.endTime).valueOf(),
                    itemProps: {
                        'data-tip': user.title
                    }
                });

            });
            this.setState({ items: itemsData });
            this.setState({ groups: groups });
            console.log(this.state.items, 'axiosGet');

        })
    }


    componentDidMount() {
        this.axiosGetEvents();
        console.log(this.state.items, 'didmount');
    }


    eventClick = (item, e) => {
        Swal.fire({
            title: item.title,
            html:
                `<div class="table-responsive">
      <table class="table">
      <tbody>
      <tr >
      <td>Title</td>
      <td><strong>` +
                item.title +
                `</strong></td>
      </tr>
      <tr >
      <td>Event Desc</td>
      <td><strong>
      ` +
                item.description +
                `
      </strong></td>
      </tr>
      <tr >
      <td>Start Time</td>
      <td><strong>` +
                moment(item.start).format('YYYY-MM-DD HH:mm') +
                `</strong></td>
      </tr>
      <tr >
      <td>End Time  </td>
      <td><strong>` +
                moment(item.end).format('YYYY-MM-DD HH:mm')
                +
                `</strong></td>
      </tr>
      </tbody>
      </table>
      </div>`,
            showDenyButton: true,
            confirmButtonColor: "#d33",
            denyButtonColor: "#3085d6",
            confirmButtonText: "Edit Event",
            denyButtonText: "Remove Event"
        }).then(result => {
            if (result.isConfirmed) {
                this.handleEventListEdit(item.id);
                console.log(item.id);
            } else if (result.isDenied) {
                this.handleEventListDelete(item.id);
                console.log(item.id);
            }
        });
    };


    render() {

        const { addModal } = this.state;

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
        const { RangePicker } = DatePicker;

        const rangeConfig = {
            rules: [{ required: true, message: 'Please select time!' }],
        };




        return (
            <Fragment>
                <Breadcrumb parent="Calender" title="Active Timeline" />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>{ActiveTimelineTitle}</h5>

                                    <Button type='primary' color="primary" style={{ background: '#4466f2' }} onClick={this.addToggle}>Add Events</Button>{''}

                                    <Modal isOpen={addModal} toggle={this.addToggle} size="lg">
                                        <ModalHeader className="edit-header" toggle={this.addToggle}>Add Event</ModalHeader>
                                        <ModalBody className="edit-body">
                                            <Form  {...layout} name="nest-messages" ref={this.formRef} onFinish={this.onFinish} validateMessages={this.validateMessages}>
                                                <Form.Item name={['event', 'title']} label="Event Title" rules={[{ required: true }]}>
                                                    <Input />
                                                </Form.Item>
                                                <Form.Item name={['event', 'group']} label="SwimLanes" rules={[{ required: true }]}>
                                                    <Select>
                                                        {groups.map((group) => {
                                                            return (
                                                                <Option value={group.title}>{group.title}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name={['event', 'description']} label="Event Description" rules={[{ required: true }]}>
                                                    <Input.TextArea />
                                                </Form.Item>
                                                <Form.Item name={['event', 'timerange']} label="Time Clock" {...rangeConfig}>
                                                    <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                                                </Form.Item>
                                                <Form.Item name={['event', 'color']} label='Color' >
                                                    <ColorPicker colorChange={this.colorChange} />
                                                </Form.Item>
                                                <Form.Item className="edit-item">
                                                    <Button color="secondary" onClick={this.onReset}>Reset</Button>
                                                    <Button color="primary" className="mr-1" type="primary" htmlType='submit' style={{ background: '#4466f2' }}>Submit</Button>
                                                    <Button color="danger" onClick={this.addToggle}>Cancel</Button>
                                                </Form.Item>
                                            </Form>
                                        </ModalBody>
                                        <ModalFooter>
                                        </ModalFooter>
                                    </Modal>

                                    <Button color="primary" type='primary' style={{ background: '#4466f2' }} onClick={this.handleViewEventsToggle}>{this.state.showEventButtonDisplay}</Button>

                                </div>
                                <div className="card-body">
                                    <div>
                                        {this.getEventListArea(this.state.showEventList)}
                                    </div>
                                    <div>
                                        {this.getEventListEditArea()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }


    colorChange = (e) => {
        console.log(e);
        this.setState({
            color: e
        })
    }


    ///add events 
    addToggle() {
        if (this.state.addModal) {
            this.setState({
                addModal: false
            })
        } else {
            this.setState({
                addModal: true
            })
        }

    }


    ///reset event

    onReset = () => {
        this.formRef.current.resetFields();
    };




    ///popover toggle
    popoverToggle() {
        if (this.state.popoverOpen) {
            this.setState({
                popoverOpen: false
            })
        } else {
            this.setState({
                popoverOpen: true
            })
        }
    }

    ///form submit
    onFinish = (values) => {

        //'range-time-picker': [rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss'), rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss')]
        console.log(values);
        let data = JSON.stringify({
            timelineID: -1,
            startTime: values.event.timerange[0].format('YYYY-MM-DDTHH:mm:ss'),
            endTime: values.event.timerange[1].format('YYYY-MM-DDTHH:mm:ss'),
            duration: 0,
            title: values.event.title,
            description: values.event.description,
            startTriggerDeltaTime: values.event.timerange[0].format('YYYY-MM-DDTHH:mm:ss'),
            startTriggerAction: null,
            endTriggerDeltaTime: values.event.timerange[1].format('YYYY-MM-DDTHH:mm:ss'),
            endTriggerAction: null,
            colour: this.state.color,
            lane: parseInt(groups.find(group => group.title === values.event.group).id)
        });

        console.log(data);
        axios.post('https://activetimelineapi.azurewebsites.net/api/events', data, { headers: { "Content-Type": "application/json" } }).then((res) => {
            console.log(res);
            this.axiosGetEvents();
            Swal.fire("Added!", "Your Event has been Added.", "success");
        }).catch((error) => {
            console.log(error);
        });
        this.setState({ addModal: false });


    }

    ///hover and click function of items
    hide = () => {
        this.setState({
            clicked: false,
            hovered: false,
        });
    };

    handleHoverChange = visible => {
        console.log(visible);
        this.setState({
            hovered: visible,
            clicked: false,
        });
    };

    handleClickChange = visible => {
        this.setState({
            clicked: visible,
            hovered: false,
        });
    };


    ///show eventslist
    getEventListArea = (show) => {
        const { groups, items, defaultTimeStart, defaultTimeEnd } = this.state;
        const today = new Date()
        if (show) {

            return (
                <EventList items={items} groups={groups} itemDelete={this.handleEventListDelete} itemEdit={this.handleEventListEdit} />
            )
        } else {

            return (
                <Timeline
                    groups={groups}
                    items={items}
                    keys={keys}
                    sidebarContent={<div>Above The Left</div>}
                    itemsSorted
                    itemTouchSendsClick={false}
                    itemRenderer={({
                        item,
                        itemContext,
                        getItemProps
                    }) => {
                        console.log(item);
                        const backgroundColor = itemContext.selected ? "orange" : item.color;
                        const borderColor = itemContext.selected ? "orange" : item.color;

                        return (
                            <button onClick={() => this.eventClick(item)} id={"Tooltip-" + item.id}
                                {...getItemProps({
                                    style: {
                                        backgroundColor: backgroundColor,
                                        borderColor
                                    }
                                })}>
                                {item.title}
                                <ItemHover text={itemContext} item={item} />
                                {/* <ItemPopover text={itemContext} item={item}
                                    deleteItem={this.handleEventListDelete}
                                    click={this.handleEventListEdit} showPopover={this.showPopoverHover} /> */}
                            </button>
                        )
                    }
                    }
                    stackItems
                    itemHeightRatio={0.75}
                    showCursorLine
                    canMove={true}
                    canResize={true}
                    defaultTimeStart={defaultTimeStart}
                    defaultTimeEnd={defaultTimeEnd}
                    onItemMove={this.handleItemMove}
                    onItemResize={this.handleItemResize}
                >

                    <TimelineMarkers>
                        <TodayMarker />
                        <CustomMarker date={Date.now()}>
                            {({ styles, date }) => {


                                const customStyles = {
                                    ...styles,
                                    backgroundColor: 'red',

                                    width: '4px'
                                }
                                return <div style={customStyles} />
                            }}




                        </CustomMarker>



                    </TimelineMarkers>



                </Timeline>


            )
        }
    }

    // showPopoverHover = (condition) => {
    //     condition ? this.setState({ showHover: false }) : this.setState({ showHover: true })
    // }


    ///view events
    handleViewEventsToggle = () => {
        if (this.state.showEventList) {
            this.setState({
                showEventList: false,
                showEventButtonDisplay: 'View Events'
            })
        } else {
            this.setState({
                showEventList: true,
                showEventButtonDisplay: 'View Timeline'
            })
        }
    }


    ///delete event in eventlist
    handleEventListDelete(index) {
        console.log(index);
        let data = JSON.stringify({ id: index })
        console.log(data);
        axios.delete('https://activetimelineapi.azurewebsites.net/api/events/' + index,
            { data: data, headers: { "Content-Type": "application/json" } }).then((res) => { console.log(res); this.axiosGetEvents(); Swal.fire("Deleted!", "Your Event has been deleted.", "success"); })
            .catch((error) => { console.log(error) });
    }


    ///edit evnet in eventlist
    handleEventListEdit(index) {
        console.log(index);
        const datalist = [...this.state.items];
        for (var i = 0; i < datalist.length; i++) {
            if (datalist[i].id === index) {
                console.log(i);
                const deleteindex = i;
                this.setState({
                    editOpen: true,
                    editindex: index,
                    editId: deleteindex
                });
                return;
            }
        }
    }

    getEventListEditArea() {
        if (this.state.editOpen) {
            const editItem = this.state.items[this.state.editId];
            const layout = {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
            };
            const defaultGroup = groups.find(group => group.id === editItem.group).title;

            const { RangePicker } = DatePicker;

            const rangeConfig = {
                rules: [{ required: true, message: 'Please select time!' }],
            };

            return (
                <Modal isOpen={this.state.editOpen} toggle={this.editToggle} size="lg">
                    <ModalHeader toggle={this.editToggle}>Edit Event</ModalHeader>
                    <ModalBody>
                        <Form {...layout} name="nest-messages" ref={this.formRef} onFinish={this.onEditFinish} >
                            <Form.Item name={['event', 'title']} label="Event Title" initialValue={editItem.title} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name={['event', 'group']} label="SwimLanes" initialValue={defaultGroup} rules={[{ required: true }]}>
                                <Select>
                                    {groups.map((group) => {
                                        return (
                                            <Option value={group.title}>{group.title}</Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name={['event', 'description']} label="Event Description" initialValue={editItem.description} rules={[{ required: true }]}>
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item name={['event', 'timerange']} label="Time Clock" initialValue={[moment(editItem.start), moment(editItem.end)]} {...rangeConfig} >
                                <RangePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>
                            <Form.Item name={['event', 'color']} label='Color'>
                                <ColorPicker colorChange={this.colorChange} />
                            </Form.Item>
                            <Form.Item>
                                <Button color="secondary" onClick={this.onReset}>Reset</Button>
                                <Button color="primary" className="mr-1" type="primary" htmlType='submit' style={{ background: '#4466f2' }}>Submit</Button>
                                <Button color="danger" onClick={this.editToggle}>Cancel</Button>
                            </Form.Item>
                        </Form> 
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>
            )
        } else { }
    }


    onEditFinish = (values) => {
        let data = JSON.stringify({
            id: this.state.editindex,
            startTime: values.event.timerange[0].format('YYYY-MM-DDTHH:mm:ss'),
            endTime: values.event.timerange[1].format('YYYY-MM-DDTHH:mm:ss'),
            title: values.event.title,
            description: values.event.description,
            lane: parseInt(groups.find(group => group.title === values.event.group).id),
            colour: this.state.color
        });
        const index = this.state.editindex;
        console.log(data);

        axios.put('https://activetimelineapi.azurewebsites.net/api/events/' + index, data, { headers: { "Content-Type": "application/json" } }).then((res) => {
            console.log(res);
            this.axiosGetEvents();
            Swal.fire("Edited!", "Your Event has been Edited.", "success");
        }).catch((error) => {
            console.log(error);
        });
        this.setState({ editOpen: false });
    }




    editToggle() {
        if (this.state.editOpen) {
            this.setState({
                editOpen: false
            })
        } else {
            this.setState({
                editOpen: true
            })
        }

    }


}




export default Calender1;

