import React, { Component } from 'react';
import { Button, Table, Space, Popconfirm, message } from 'antd'
import 'antd/dist/antd.css';
import moment from 'moment'


class EventList extends Component {
    constructor(props) {

        super(props);


    }





    render() {
        const data = this.props.items;

        const dataList = [];

        data.map((item) => {
            dataList.push({
                title: item.title,
                description: item.description,
                start: moment(item.start).format('YYYY-MM-DD HH:mm:ss'),
                end: moment(item.end).format('YYYY-MM-DD HH:mm:ss'),
                key: item.id
            })
        });

        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title'
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Start_Time',
                dataIndex: 'start',
                key: 'start',
            },
            {
                title: 'End_Time',
                dataIndex: 'end',
                key: 'end',
            }, {
                title: 'Action',
                key: 'action',
                render: (text, record, index) => {
                    return (
                        <Space size='middle'>
                            <Button onClick={() => this.handleItemEdit(text)} style={{ margin: '0 20px', background: '#4466f2' }} type='primary'>Edit</Button>
                            <Popconfirm title='Sure to delete?' onConfirm={() => this.handleItemDelete(text)}>
                                <Button style={{ margin: '0 20px' }} type='dashed' danger>Delete</Button>
                            </Popconfirm>
                        </Space>)
                }
            }];


        return (
            <Table columns={columns} dataSource={dataList} />
        )
    }


    handleItemDelete = (text) => {
        this.props.itemDelete(text.key);
    }

    handleItemEdit = (text) => {
        this.props.itemEdit(text.key);
    }


}
export default EventList;