import { Input } from 'reactstrap'
import React, { Component } from 'react'

export default class InputColor extends Component {
    constructor(props) {
        super(props);

    }
    render() {

        return (

            <Input
                onChange={this.colorChange}
                type="color"
                name="color"
                id="examplecolor"
                placeholder="color placeholder"
                style={{ height: '32px' }}
            />

        )
    }
    colorChange = (e) => {
        this.props.colorChange(e.target.value);
    }
}